import {
  FormStatus,
  getDefaultPaymentOptionId,
} from '../../../../utils/state/initialStateFactory';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CouponsErrorType } from '../../../../types/errors';
import { CreateActionParams } from '../actions';
import { bookingsLoginClick } from '@wix/bi-logger-wixboost-ugc/dist/src/v2';

export type OnLogin = (user?: IUser) => Promise<void>;

export function createOnLoginAction({
  getControllerState,
  context,
  internalActions,
}: CreateActionParams): OnLogin {
  return async (user) => {
    const [state, setState] = getControllerState();
    const { slotAvailability } = state;
    const { serviceId, startDate } = slotAvailability.slot!;
    const { formApi, settings, wixSdkAdapter, biLogger } = context;
    const { errorHandlers } = internalActions;

    biLogger?.report(bookingsLoginClick({}));

    setFieldsValuesInStorage(wixSdkAdapter, {
      state: {
        valid: false,
        errors: {},
      },
      submission: {},
    });

    if (user) {
      try {
        const memberDetails = await formApi.getMemberDetails(user.id);
        const pricingPlanDetails = await formApi.getPricingPlanDetails({
          serviceId: serviceId!,
          startTime: startDate!,
        });
        const selectedPaymentOptionId = getDefaultPaymentOptionId(
          settings,
          state.service.payment,
          pricingPlanDetails,
        );

        setState({
          memberDetails,
          pricingPlanDetails,
          selectedPaymentOptionId,
          status: FormStatus.IDLE,
          overrideDefaultFieldsValues: true,
        });
      } catch (error) {
        errorHandlers.addError(error);
        setState({ status: FormStatus.IDLE });
      }
    } else {
      errorHandlers.addError(CouponsErrorType.GENERAL_SERVER_ERROR);
    }
  };
}
