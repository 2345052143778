import { CouponsErrorType, GenericErrorType } from '../../../../types/errors';
import { FormStatus } from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions';

export type SetCoupon = (couponCode: string) => void;

export function createSetCouponAction({
  getControllerState,
  internalActions,
}: CreateActionParams): SetCoupon {
  return async (couponCode: string) => {
    const [, setState] = getControllerState();
    const { calculatePaymentDetails, errorHandlers } = internalActions;

    errorHandlers.clearErrorByTypes([CouponsErrorType, GenericErrorType]);
    if (couponCode) {
      setState({
        status: FormStatus.PROCESSING_PAYMENT_DETAILS,
      });
      await calculatePaymentDetails(couponCode);
    }
  };
}
